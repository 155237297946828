/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { useEffect } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useDispatch } from 'react-redux';
import { pageScroll } from 'helpers/analytics/page-scroll';
import { SegmentPageEvent } from 'helpers/analytics/segment-helper';
import { AGE_GATE_COOKIE_NAME } from 'helpers/constants/cookies';
import { COMMON_COOKIES, GEO_COOKIES, MUNDY_STORE_ID } from 'helpers/types/cookies';
import { GEO_WORKER_HEADER } from 'helpers/types/geo-header';
import { parsedCookieValue } from 'helpers/utils/cookies';
import { getDefaultGeolocationDispensary, setLocalhostCookies } from 'helpers/utils/default-dispensaries';
import { isDevelopment } from 'helpers/utils/environment';
import { updateHost } from 'redux/reducers/host';
import { LocaleStorage, ResponseError, createClient } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import { Log } from '../helpers/errorLogger';

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data, locale }: SlugProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  LocaleStorage.locale = locale;

  useEffect(() => {
    dispatch(updateHost({ host: data?.pageFolder?.host, isCurrentHostOH: data?.pageFolder?.cookies?.isOH || null }));
  }, []);

  useEffect(() => {
    //to keep track of if user has already passed the %, so we don't sent the same event multiple times
    const hasUserScrolledPagePercent = {
      25: false,
      50: false,
      75: false,
      90: false,
    };
    const getHeight = () => {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
      );
    };
    const scrollTrack = () => {
      const winHeight = window.innerHeight || (document.documentElement || document.body).clientHeight;
      const docHeight = getHeight();
      const scrollTop = window.pageYOffset || (document.documentElement || document.body).scrollTop;
      const trackLength = docHeight - winHeight;
      const pageScrolled = Math.floor((scrollTop / trackLength) * 100);
      Object.keys(hasUserScrolledPagePercent).forEach((percent) => {
        if (pageScrolled >= parseInt(percent) && !hasUserScrolledPagePercent[percent]) {
          hasUserScrolledPagePercent[percent] = true;
          pageScroll(`Scroll ${percent}per`, {});
        }
      });
    };
    const segmentPageData = {
      state_name: data?.pageFolder?.cookies.stateID,
      dispensary_id: data?.pageFolder?.cookies.storeID,
    };
    SegmentPageEvent(segmentPageData);
    window?.addEventListener('scroll', scrollTrack);
    return () => {
      window?.removeEventListener('scroll', scrollTrack);
    };
  }, [router.asPath]);

  // const { formatMessage } = useFormat({ name: 'common' });

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  return <FrontasticRenderer data={data} tastics={tastics} />;
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  const urls: any = params?.slug;
  if (urls?.some((string) => string.includes('-sitemap')) && urls) {
    const { fetchSitemapPageType } = await import('helpers/utils/fetch-sitemap');
    const xml = await fetchSitemapPageType({ urls, req, res });
    if (xml) {
      return {
        props: {
          isXml: true,
        },
      } as any;
    }
  }
  LocaleStorage.locale = locale;
  const isOH = req.headers.host.includes('oh');
  const currentHost = isOH ? 'OH' : 'MAIN';
  const isPreview = req.headers.host.includes('preview');
  const preview = isPreview ? 'PREVIEW' : 'NON_PREVIEW';
  const overrideStudioCFUrl = process.env.NEXT_PUBLIC_USE_CF_URL === 'true' ? 'YES' : 'NO';
  const overrideUrl = process.env.NEXT_PUBLIC_CF_URL;
  const startReqTime = new Date();
  const id = 'gti-id' + Math.random().toString(16).slice(2);
  console.info('🚀 ~ id: startReqTime', id, startReqTime);

  let userSignInSession = null;
  let userHomeDis = null;
  const startTimeCognito = new Date();
  let cognitoLogLabel = 'Cognito - Non Logged In User';
  if (resolvedUrl.includes('/account')) {
    const Auth = (await import('helpers/utils/cognito')).default;
    userSignInSession = await Auth.checkSessionForServer(req);
    console.info('🚀 ~ userSignInSession:', userSignInSession);

    if (resolvedUrl.includes('/account') && userSignInSession) {
      cognitoLogLabel = 'Cognito - Logged In User';
      try {
        const userData = await Auth.getAttributes();
        userHomeDis = userData['custom:current_dispensary'];
      } catch (error) {
        console.error('Error getting user attributes:-', error);
      }
    }
  }
  const stopTimeCognito = new Date();

  //Protect Authenticated Route
  if (resolvedUrl.includes('/account') && !userSignInSession) {
    console.info('Protected route called!!!, returning not found', query);
    return {
      redirect: {
        permanent: false,
        destination:
          resolvedUrl.includes('/account/orders') && query.code && query.email_verification === 'true'
            ? `/?code=${query.code}&email_verification=true`
            : '/',
      },
      props: {},
    };
  }

  // need to be uncommented once worker is set on DNS
  const cookies = req.headers?.cookie && req.headers?.cookie.split(' ');
  const stateCookie = cookies ? parsedCookieValue(cookies, GEO_COOKIES.STATE) : null;
  const disCookie = cookies ? parsedCookieValue(cookies, GEO_COOKIES.DISPENSARY) : null;
  const storeIdCookie = cookies ? parsedCookieValue(cookies, GEO_COOKIES.DISPENSARY_ID) : null;
  const stickyBarCookies = cookies ? parsedCookieValue(cookies, COMMON_COOKIES.STICKY_BAR) : null;
  const ageGateCookie = cookies ? parsedCookieValue(cookies, AGE_GATE_COOKIE_NAME) : null;

  let stateID = stateCookie;
  let disID = disCookie;
  let storeID = storeIdCookie;

  //Cloudflare worker
  if (
    req.headers[GEO_WORKER_HEADER.STATE] &&
    req.headers[GEO_WORKER_HEADER.DISPENSARY] &&
    req.headers[GEO_WORKER_HEADER.DISPENSARY_ID]
  ) {
    stateID = req.headers[GEO_WORKER_HEADER.STATE] as string;
    disID = req.headers[GEO_WORKER_HEADER.DISPENSARY] as string;
    storeID = req.headers[GEO_WORKER_HEADER.DISPENSARY_ID] as string;
  } else if (!stateID || !disID || !storeID) {
    if (isOH) {
      stateID = 'ohio';
    }
    const defaultGeolocation = getDefaultGeolocationDispensary(stateID || 'all');
    stateID = defaultGeolocation.stateSlug;
    disID = defaultGeolocation.dispensarySlug;
    storeID = defaultGeolocation.storeID;
  }

  // Set cookies on localhost
  setLocalhostCookies(req, res, params);

  if (disID === 'mundelein-lounge') {
    storeID = MUNDY_STORE_ID.PROD;
  }

  if (
    resolvedUrl.includes('/blog') &&
    (stateID === 'minnesota' || stateID === 'ohio' || disID === 'lake-in-the-hills')
  ) {
    return {
      redirect: {
        permanent: false,
        destination: '/compliance',
      },
      props: {},
    };
  }

  const frontastic = createClient();
  const startTimeData = new Date();
  console.info('🚀 ~ startTimeData:', id, startTimeData, userHomeDis);
  const data = await frontastic.getRouteData(params, locale, query, req, res, {
    stateID,
    disID,
    storeID,
    currentHost,
    preview,
    overrideUrl,
    overrideStudioCFUrl,
    id,
    userHomeDispensarySlug: userHomeDis,
  });
  const stopTimeData = new Date();

  if ('pageFolder' in data) {
    const protocol = isDevelopment() ? 'http://' : 'https://';
    const host = protocol + req.headers.host;
    data.pageFolder.host = host;
    data.pageFolder.cookies = {
      stateID,
      disID,
      storeID,
      isOH: isOH ? 'true' : null,
      stickyBarCookies,
      ageGate: ageGateCookie ? 'true' : 'false',
    };
  }

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (resolvedUrl.includes('/:product_name')) {
    const productData = (data as any)?.data?.dataSources['__master']?.variants_details;
    if (!productData) {
      console.warn('PRODUCT NOT FOUND: ', resolvedUrl);
      return {
        notFound: true,
      };
    }
    const productVariant: any = Object?.values(productData)[0];
    const productSlug = productVariant?.url_slug || productVariant?.unique_slug;
    const replacedUrl = resolvedUrl?.replace('/:product_name', `/${productSlug}`);
    return {
      redirect: {
        permanent: false,
        destination: replacedUrl,
      },
      props: {},
    };
  }

  const ds = (data as any)?.data?.dataSources;
  const traceData = [];
  if (ds) {
    Object?.values(ds)?.map((v) => {
      if (v['traceData']) {
        traceData.push(v['traceData']);
      }
    });
  }

  console.log('🚀 ~ SSR Get Route Data Breakdown:');
  console.table(traceData);

  console.log('🚀 ~ SSR API Time Consolidation:');
  console.table([
    {
      name: 'Data Route',
      time: stopTimeData.getTime() - startTimeData.getTime(),
      timeFromStart: stopTimeData.getTime() - startReqTime.getTime(),
    },
    {
      name: cognitoLogLabel,
      time: stopTimeCognito.getTime() - startTimeCognito.getTime(),
      timeFromStart: stopTimeCognito.getTime() - startReqTime.getTime(),
    },
  ]);

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: data || null,
      locale: locale,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};
